import React, {useEffect, useRef, useState} from "react";
import {childrenWithProps, classNames} from "../../tools/react.dom.helpers";
import {Icon, Text} from "../index";
import ICON from "../const/icon";
import {_t} from "../../../../js/translation";

const Collapsible = ({open, children, className, caption, closeCaption, captionSize = Collapsible.TITLE_SIZE.DEFAULT, subcaption, subcaptionSize = Text.SIZE.SMALL, type = Collapsible.TYPE.PRIMARY, icon, iconColor, onClick, hasErrors, actions, headerPadding, contentPadding, previewSize = 0, view, customHeaderHtml}) => {
    const [isOpen, setIsOpen] = useState(open);
    const [height, setHeight] = useState(open ? undefined : previewSize);
    const ref = useRef(null);

    const handelCollapseOpening = () => {
        setIsOpen((prev) => !prev);
    };

    useEffect(() => {
        if (!height || !isOpen || !ref.current) return undefined;
        const resizeObserver = new ResizeObserver((el) => {
            setHeight(el[0].contentRect.height);
        });
        resizeObserver.observe(ref.current);
        return () => {
            resizeObserver.disconnect();
        };
    }, [height, isOpen]);

    useEffect(() => {
        if (isOpen) setHeight(ref.current?.getBoundingClientRect().height);
        else setHeight(previewSize);
    }, [isOpen]);

    return (
        <>
            <div className={classNames(className, 'collapsible', type && 'collapsible-type__' + type, view && 'collapsible-view__' + view)}>
                <div className={classNames(
                    'collapsible__header',
                    headerPadding && 'collapsible__header--pd-inline-' + headerPadding,
                    isOpen && 'open'
                )} onClick={(event) => {
                    handelCollapseOpening();
                    onClick?.(event);
                }}>
                    {icon && <Icon icon={icon} color={iconColor}/>}
                    {caption && <>
                        {_.isString(caption) ?  <h3 className={classNames('collapsible__header-title', captionSize && 'collapsible__title-' + captionSize)}>{_t(isOpen && closeCaption ? closeCaption : caption)}
                            {subcaption && <Text className={classNames('collapsible__header-subtitle')} size={subcaptionSize} caption={subcaption} />}
                        </h3> : caption
                        }
                    </>
                    }
                    {hasErrors && <Icon className="collapsible__header-icon" icon={ICON.STATUS.ERROR} color={Icon.COLOR.DANGER}/>}
                    {actions}

                    {customHeaderHtml &&
                        <div className="collapsible__header--custom-html">
                            {childrenWithProps(customHeaderHtml, (child, id) => ({
                                key: id
                            }))}
                        </div>
                    }

                    <Icon className="collapsible__header-toggle-icon" icon={Icon.ICON.EXPAND}/>
                </div>

                <div className={classNames('collapsible__content-wrapper', isOpen && '--visible')} style={{height}}>
                    <div ref={ref}>
                        <div className={classNames('collapsible__content',
                            contentPadding && 'collapsible__content--pd-inline-' + contentPadding)}>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
Collapsible.VIEW = {
    TOGGLER_FROM_BOTTOM: 'toggler-from-bottom',
}
Collapsible.TYPE = {
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    TRANSPARENT_BG: 'default',
}
Collapsible.TITLE_SIZE = {
    LARGE: 'large',
    DEFAULT: 'default',
    SMALL: 'small',
}
Collapsible.HEADER_PADDING_INLINE = {
    NONE: 'none'
}

Collapsible.CONTENT_PADDING_INLINE = {
    NONE: 'none',
}

export default Collapsible;