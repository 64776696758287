import React, { useState, useEffect } from "react";

export default function IfScreen({phone, tablet, desktop, screenSizeMoreThan, screenSizeLessThan, children = null}) {
    const width = useCurrentWidth();

    const shouldRender = (
        (phone && width < 768) ||
        (tablet && width >= 768 && width < 1024) ||
        (desktop && width >= 1024) ||
        (screenSizeMoreThan !== undefined && width > screenSizeMoreThan) ||
        (screenSizeLessThan !== undefined && width < screenSizeLessThan)
    );

    return shouldRender ? children : null;
}

// Helper function to get screen category
function getScreen(width) {
    return width < 768 ? "phone" : width < 1024 ? "tablet" : "desktop";
}

// Helper function to get the current window width
const getWidth = () =>
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

// Custom hook to get current screen type
export function useScreen() {
    const width = useCurrentWidth();
    return getScreen(width);
}

// Custom hook to listen for window resize and get the current width
export function useCurrentWidth() {
    const [width, setWidth] = useState(getWidth());

    // in this case useEffect will execute only once because
    // it does not have any dependencies.
    useEffect(() => {
        // timeoutId for debounce mechanism
        let timeoutId = null;

        const resizeListener = () => {
            // prevent execution of previous setTimeout
            clearTimeout(timeoutId);
            // change width from the state object after 150 milliseconds
            timeoutId = setTimeout(() => setWidth(getWidth()), 50);
        };

        window.addEventListener("resize", resizeListener);

        return () => {
            // remove resize listener
            window.removeEventListener('resize', resizeListener);
        }
    }, [])

    return width;
}
