import React, { Fragment } from "react";
import {classNames} from "../../tools/react.dom.helpers";
import Heading from "../heading";
import {_t} from "../../../../js/translation";

const BlockHeader = ({ caption, type, children, weight = BlockHeader.WEIGHT.BOLD, hasActions, className }) => {

    const headerType = type => {
        switch (type) {
            case BlockHeader.TYPE.REPORT_MODAL:
                return Heading.TYPE.HEADING_SMALL;
            default:
                return Heading.TYPE.H3
        }
    };

    const headingType = headerType(type);

   return <header className={classNames(className, 'block-header', type && type, hasActions && '--has-actions')}>
        {caption ?
            <>
                <Heading className={weight} type={headingType}>{_t(caption)}</Heading>
                {children}
            </> :
                <Heading className={weight} type={headingType}>{children}</Heading>
        }
    </header>
}

BlockHeader.WEIGHT = {
    NORMAL: 'normal',
    MEDIUM: 'medium',
    BOLD: 'bold',
    EXTRA_BOLD: 'extra-bold',
    LIGHT: 'light',
}

BlockHeader.TYPE = {
    REPORT_MODAL: 'block-header--report-modal'
}
export default BlockHeader;