import React, {forwardRef, useContext, useEffect, useRef, useState} from "react";
import {childrenWithProps, classNames, generateId} from "../../tools/react.dom.helpers";
import {_t} from "../../../../js/translation";
import Icon from "../icon";
import {Link} from "react-router-dom";
import {TooltipContext} from "../tooltip.context";
import {useScreen} from "../if.screen";

const stopPropagation = (handler) =>
    handler ?
        (event) => {
            event.stopPropagation();
            handler(event);
        }
        : null


const Button = ({id, name, className, type=Button.TYPE.BUTTON, caption, icon, iconColor, indicatorColor, title, template=Button.TEMPLATE.PRIMARY, disabled, checked, onClick, children, ref, form, dataActiveStatus, fontStyle, iconSvg}) =>
    <ButtonImplementation
        id={id} name={name} className={className} dataActiveStatus={dataActiveStatus}
        type={type} caption={caption} icon={icon} iconColor={iconColor} fontStyle={fontStyle} iconSvg={iconSvg}
        indicatorColor={indicatorColor} title={title}
        disabled={disabled} checked={checked} onClick={onClick}
        children={children} ref={ref} form={form} {...template}
    />


const ButtonImplementation = forwardRef(({
     // common html
     id, name, className, type = Button.TYPE.BUTTON, dataActiveStatus,
     // visual
     caption, icon, iconColor, color, template=Button.TEMPLATE.PRIMARY, indicatorColor, title, caret, fontStyle, iconSvg,
     // state
     disabled, checked,
     // events
     onClick, form,
     // children
     children,
}, ref) => {
        return <button
            ref={ref}
            type={type}
            id={id}
            disabled={disabled}
            name={name}
            className={classNames(className, 'btn', 'btn--' + color, indicatorColor && 'btn--has-indicator', icon && 'btn--has-icon', !caption && 'btn--no-text', fontStyle &&  fontStyle)}
            onClick={stopPropagation(onClick)}
            title={title}
            form={form}
            data-active={dataActiveStatus}
        >
            {children || <>
                {iconSvg && <i className={classNames('icon icon-svg', iconColor && iconColor)} dangerouslySetInnerHTML={{__html: iconSvg}}/>}
                {icon && <Icon icon={icon} color={iconColor}/>}
                {caption && <span className="btn__txt">
                    {_t(caption)}
                </span>}
            </>}
            {indicatorColor && <span className={classNames('indicator', 'indicator-' + indicatorColor)}/>}
            {caret && <span className="caret"></span>}
        </button>;
    }
)

Button.TYPE = {
    RESET: 'reset',
    SUBMIT: 'submit',
    BUTTON: 'button'
}
export default Button;




export const LinkButton = ({to, href, target, id, className, color, icon, iconColor, caption, onClick, children, template=Button.TEMPLATE.LINK, fontStyle, iconSvg}) =>
    <LinkButtonImplementation
        to={to} href={href} target={target} id={id} onClick={onClick}
        className={className} color={color} icon={icon} fontStyle={fontStyle} iconSvg={iconSvg}
        iconColor={iconColor} caption={caption} children={children} {...template}
    />

const LinkButtonImplementation = ({
      // Common HTML
      to, href, target, id, className, onClick,
      // Visual
      color, icon, iconColor, fontStyle, iconSvg,
      // Caption & Children
      caption, children
  }) => {
    const inner = children || <>
        {iconSvg && <i className={classNames('icon icon-svg', iconColor && iconColor)} dangerouslySetInnerHTML={{__html: iconSvg}}/>}
        {icon && <Icon icon={icon} color={iconColor}/>}
        {caption && <span className="btn__txt">
            {_t(caption)}
        </span>}
    </>;

    return to ? <Link onClick={onClick} to={to} id={id} className={classNames('btn', 'btn--' + color, className, !caption && 'btn--no-text', fontStyle && fontStyle)}>{inner}</Link>
        : <a href={href} target={target} id={id} className={classNames('btn', 'btn--' + color, className, !caption && 'btn--no-text', fontStyle && fontStyle)} rel="noopener noreferrer">{inner}</a>
}



export const BtnBar = ({children, className, alignment, type, content}) => (
    <div className={classNames('btn-bar', alignment && 'btn-bar--' + alignment, type && 'btn-bar--' + type, content && 'btn-bar--content-' + content,  className)} >
        <div className="btn-bar__row">{children}</div>
    </div>
)

BtnBar.ALIGNMENT = {
    CENTER: 'center',
    BASELINE: 'baseline'
}

BtnBar.TYPE = {
    WRAP: 'wrap',
    COLUMN: 'column',
}

BtnBar.CONTENT = {
    CENTER: 'center',
    RIGHT: 'right',
}



export const ClosePopupButton = ({targetId, name, className, caption, icon, iconColor, template=Button.TEMPLATE.SECONDARY, type, onClick, disabled, form, children, iconSvg}) =>
    <ClosePopupButtonImplementation
        targetId={targetId} name={name} className={className}
        caption={caption} icon={icon} iconColor={iconColor} iconSvg={iconSvg}
        onClick={onClick} disabled={disabled} type={type} form={form}
        children={children} {...template}/>

const ClosePopupButtonImplementation = ({
    // Common HTML
    targetId, name, className, type = Button.TYPE.BUTTON,
    // Visual
    caption, icon, iconColor, color, iconSvg,
    // Events
    onClick, disabled, form,
    // Children
    children
    }) =>
    <button disabled={disabled} name={name} className={classNames('btn btn--close', 'btn--'+color, !caption && 'btn--no-text', className)} form={form} onClick={onClick} type={type} data-dismiss="modal">
        { children || <>
            {iconSvg && <i className={classNames('icon icon-svg', iconColor && iconColor)} dangerouslySetInnerHTML={{__html: iconSvg}}/>}
            { icon && <Icon icon={icon} color={iconColor} /> }
            { caption && <span className="btn__txt">{_t(caption)}</span> }
        </> }
    </button>

export const OpenPopupButton = ({targetId, name, className, caption, icon, iconColor, template=Button.TEMPLATE.SECONDARY, type, onClick, disabled, form, children, iconSvg}) =>
    <OpenPopupButtonImplementation
        targetId={targetId} name={name} className={className}
        caption={caption} icon={icon} iconColor={iconColor} iconSvg={iconSvg}
        onClick={onClick} disabled={disabled} type={type} form={form}
        children={children} {...template}/>

const OpenPopupButtonImplementation = ({
        // Common HTML
        targetId, name, className, type = Button.TYPE.BUTTON,
        // Visual
        caption, icon, iconColor, color, iconSvg,
        // Events
        onClick, disabled, form,
        // Children
        children
    }) =>
    <button disabled={disabled} name={name} className={classNames('btn', 'btn--'+color, !caption && 'btn--no-text', className)} onClick={onClick} type={type} form={form} data-toggle="modal" data-target={targetId}>
        { children || <>
            {iconSvg && <i className={classNames('icon icon-svg', iconColor && iconColor)} dangerouslySetInnerHTML={{__html: iconSvg}}/>}
            { icon && <Icon icon={icon} color={iconColor} /> }
            { caption && <span className="btn__txt">{_t(caption)}</span> }
        </> }
    </button>



export const CollapseButton = ({targetId, name, className, caption, icon, iconColor, template=Button.TEMPLATE.SECONDARY_OUTLINE, type, onClick, disabled, children, trackState, iconSvg}) =>
    <CollapseButtonImplementation
        targetId={targetId} name={name} className={className}
        caption={caption} icon={icon} iconColor={iconColor} iconSvg={iconSvg}
        onClick={onClick} disabled={disabled} type={type}
        children={children} {...template}
        trackState={trackState}/>

const CollapseButtonImplementation = ({
      // Common HTML
      targetId, name, className, type = Button.TYPE.BUTTON,
      // Visual
      caption, icon, iconColor, color, iconSvg,
      // Events
      onClick, disabled, trackState,
      // Children
      children
  }) =>
    <button disabled={disabled} name={name} className={classNames('collapsed btn collapse__toggler', 'btn--'+color, trackState && 'collapse__toggler-track-state',  !caption && 'btn--no-text', className)} onClick={onClick} type={type} data-toggle="collapse" data-target={'#'+targetId} aria-expanded="false" aria-controls={targetId}>
        { children || <>
            {iconSvg && <i className={classNames('icon icon-svg', iconColor && iconColor)} dangerouslySetInnerHTML={{__html: iconSvg}}/>}
            { icon && <Icon icon={icon} color={iconColor} /> }
            { caption && <span className="btn__txt">{_t(caption)}</span> }
        </> }
    </button>


export const DropdownButton = ({id = generateId(), name, className, containerClass, caption, icon, onClick, disabled, caret, mobBar=true, position = DropdownButton.POSITION.LEFT, children, header, type, dropdownType, template=Button.TEMPLATE.SECONDARY, forceScrollRef, customBtnHtml, iconSvg}) =>
    <DropdownButtonImplementation
        id={id} name={name} className={className}
        containerClass={containerClass} caption={caption} icon={icon} iconSvg={iconSvg}
        onClick={onClick} disabled={disabled} caret={caret} mobBar={mobBar}
        position={position} children={children} header={header} type={type}
        dropdownType={dropdownType} forceScrollRef={forceScrollRef} {...template}
        customBtnHtml={customBtnHtml}
    />

const DropdownButtonImplementation = ({
  // Common HTML
  id = generateId(), name, className, containerClass,
  // Visual
  caption, icon, caret, position, header, type, dropdownType, color, iconColor, iconSvg,
  // State
  mobBar=true, disabled,
  // Children
  children, customBtnHtml,
  // Events
  onClick,
  forceScrollRef
}) => {
    const [isOpen, setIsOpen] = useState(false);
    //const [isPositioned, setPositioned] = useState(false);
    const dropdownRef = useRef(null);
    const buttonRef = useRef(null);
    const screen = useScreen();


    useEffect(() => {
        if (isOpen) {
            document.body.classList.add('--dropdown-opened');
            document.addEventListener('mousedown', handleClickOutside);
            document.addEventListener('scroll', handleDocumentScroll);
            window.addEventListener('resize', handleWindowResize);
        } else {
            document.body.classList.remove('--dropdown-opened');
        }
        return () => {
            document.body.classList.remove('--dropdown-opened');
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('scroll', handleDocumentScroll);
            window.removeEventListener('resize', handleWindowResize);
        };
    }, [isOpen]);

    useEffect(() => {
        if (forceScrollRef) {
            forceScrollRef.current = isOpen ? buttonRef.current : null;
        }
    }, [forceScrollRef, isOpen]);

    useEffect(() => {
        if (buttonRef.current && screen !== 'phone') {
            //calculateDropdownPosition(position);
        }
    }, [isOpen, position]);

    const handleWindowResize = () => {
        if (isOpen && buttonRef.current) {
            //calculateDropdownPosition(position);
        }
    };

    const handleDocumentScroll = () => {
        if (isOpen && buttonRef.current) {
            //calculateDropdownPosition(position);
        }
    };

    const handleClickOutside = (event) => {
        if (isOpen && dropdownRef.current && !dropdownRef.current.contains(event.target) && !buttonRef.current.contains(event.target)) {
            setIsOpen(false);
            //setPositioned(false);
        }
    };

    const handleButtonClick = () => {
        isOpen;
        setIsOpen(!isOpen);
    };

    const handleItemClick = (event) => {
        setIsOpen(false);
    };


    return <div className={classNames('dropdown', mobBar && 'dropdown__mobile-bar', containerClass)}>
        {mobBar && <div className={classNames('dropdown__mob-cover', isOpen && 'show')}></div>}
        <button id={id} disabled={disabled} name={name}
                className={classNames('btn btn--dropdown', 'btn--' + color, type && 'btn--' + type, !caption && 'btn--no-text', className)}
                type="button" ref={buttonRef} onClick={(event) => {handleButtonClick(); onClick?.(event)}}>
            {iconSvg && <i className={classNames('icon icon-svg', iconColor && iconColor)} dangerouslySetInnerHTML={{__html: iconSvg}}/>}
            {icon && <Icon icon={icon} color={iconColor}/>}
            {caption && <span className="btn__txt">{_t(caption)}</span>}
            {customBtnHtml && <span className="btn__txt-html">
                {childrenWithProps(customBtnHtml, (child, id) => ({
                    key: id
                }))}
            </span>
            }
            {caret && <span className="caret"><Icon icon={Icon.ICON.CHEVRON_DOWN} type={Icon.TYPE.REGULAR}/></span>}
        </button>
        <div className={classNames("dropdown-menu", position, dropdownType && 'dropdown-menu--' + dropdownType, isOpen && 'show', (isOpen) && 'visible')}
             ref={dropdownRef}>
            {header && <DropdownHeader header={header}/>}

            {React.Children.map(children, (child) =>
                child ? React.cloneElement(child, {
                    onClick: (event) => {
                        child.props.onClick?.(event); // call original onClick
                        handleItemClick(event); // close dropdown
                    },
                }) : null
            )}
        </div>
    </div>
}

DropdownButton.POSITION = {
    RIGHT: 'right',
    LEFT: 'left',
    TOP_LEFT: 'top_left',
    TOP_RIGHT: 'top_right'
};

DropdownButton.Separator =  () => <hr/>

export const DropdownHeader = ({header}) => <>
    <h6 className="dropdown-header">{_t(header)}</h6>
    <div className="dropdown-divider" style={{margin: '1em'}}/>
</>




Button.TEMPLATE = DropdownButton.TEMPLATE = {
    PRIMARY: {
        color: 'primary'
    },
    SECONDARY: {
        color: 'secondary'
    },
    SECONDARY_OUTLINE: {
        color: 'secondary-outline'
    },
    DISABLED: {
        color: 'disabled'
    },
    SECONDARY_OUTLINE_TOOLTIP: {
        color: 'secondary-outline secondary-outline_tooltip'
    },
    ACTION: {
        color: 'action-secondary'
    },
    ACTION_TOOLTIP: {
        color: 'action-tooltip'
    },
    ACTION_TOOLTIP_DANGER: {
        color: 'action-tooltip action-tooltip-danger'
    },
    LINK: {
        color: 'link'
    },
    LINK_PRIMARY: {
        color: 'link-primary'
    },
    LINK_DANGER: {
        color: 'link-danger'
    },
    SUCCESS: {
        color: 'success',
    },
    SUCCESS_OUTLINE: {
        color: 'success-outline',
    },
    DANGER: {
        color: 'danger'
    },
    DANGER_LITE: {
        color: 'secondary-outline',
        iconColor: 'danger'
    },
    BACK: {
        color: 'back',
        iconColor: 'primary'
    },
    THEME_TOGGLER: {
        color: 'secondary-outline theme-toggler',
    },
    LOCATION: {
        color: 'location',
    }
};

Button.FONT_STYLE = DropdownButton.FONT_STYLE = {
    BOLD: '_bold',
    BOLD_ITALIC: '_bold-italic',
    ITALIC: '_italic',
}