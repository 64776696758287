import React from 'react';
import { Provider } from 'react-redux';
import { createStore as _createStore, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import { render as _render, hydrate as _hydrate } from 'react-dom';
import thunk from 'redux-thunk';
import moment from 'moment-timezone';
import alertify from 'alertifyjs';
import {_t} from '../js/translation';
import Config from "../app/common/config";
import convertPHPDateFormat from "../app/common/tools/convertPHPDateFormat";
import getUserDateFormat from "../app/common/tools/getUserDateFormat";

export const TIME_FORMAT = 'hh:mm a';
export const MILITARY_TIME_FORMAT = 'HH:mm';
export const DATE_FORMAT = 'Y-MM-DD';

/**
 * Create redux store with default middleware
 *
 * @param {func} reducer
 * @return {Store}
 */
export function createStore(reducer) {
    if (process.env.NODE_ENV !== 'production') {
        const logger = createLogger({
            duration: true,
            collapsed: true,
            timestamp: false,
        });

        const createStoreWithMiddleware = applyMiddleware(thunk, logger)(_createStore);

        const store = createStoreWithMiddleware(
            reducer,
            window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() // to allow REDUX dev tools extension
        );
        return store;
    }

    return _createStore(reducer, applyMiddleware(thunk));
}

/**
 * Render helper
 *
 * @param {Store} store
 * @param {Component} App
 * @param {Element} element
 */
export function render(store, App, element) {
    return _render(
        <Provider store={store}>
            <App />
        </Provider>,
        element
    );
}

/**
 * Hydrate helper
 *
 * @param {Store} store
 * @param {Component} App
 * @param {Element} element
 */
export function hydrate(store, App, element) {
    return _hydrate(
        <Provider store={store}>
            <App />
        </Provider>,
        element
    );
}

/**
 * Parse given date string and return Date instance
 *
 * @param {String} dateString
 * @return {Moment}
 */
export function parseDate(dateString) {
    return moment(dateString);
}

export function parseDateOrNull(dateString) {
    if (dateString && moment(dateString, moment.ISO_8601, true).isValid()){
        return moment(dateString);
    }

    return null;
}

/**
 * Format time of a date
 *
 * @param {String} dateString
 * @return {String}
 */
export function formatTime(dateString, timeFormat = TIME_FORMAT) {
    return parseDate(dateString).format(timeFormat);
}

/**
 * Format a date
 *
 * @param {String} dateString
 * @return {String}
 */
export function formatDate(dateString) {
    return parseDate(dateString).format(getUserDateFormat());
}

/**
 * Generic error handler for http requests
 * 
 * @param error
 * @param dispatch
 * @param setError
 */
export function errorHandler(error, dispatch, setError) {
    if (typeof error.response.data.code != "undefined" && error.response.data.code != 400 && error.response.data.code != 409) {
        notify.error(error.response.data.message);
        return;
    }
    
    if (setError) {
        dispatch(setError(error.response.data));
    }
}

/**
 * Generic error handler for http requests
 * 
 * @param error
 * @param dispatch
 * @param setError
 */
export function setErrorHandler(error, dispatch, setError) {
    dispatch(setError(error.response.data));
}

export const notify = {
    /**
     * Displays a success notification
     * @param {string} message - The message text
     * @param {string|null} title - The title of the notification (optional)
     */
    success(message, title = null) {
        const formattedMessage = this._formatMessage(message, title, 'p', 'p--primary');
        this._showNotification('success', formattedMessage);
    },

    /**
     * Displays an error notification
     * @param {string} message - The message text
     * @param {string|null} title - The title of the notification (optional)
     */
    error(message, title = null) {
        const formattedMessage = this._formatMessage(message, title, 'span', null, true);
        this._showNotification('error', formattedMessage);
    },

    /**
     * Formats the notification message
     * @param {string} message - The main text
     * @param {string|null} title - The title of the notification
     * @param {string} titleTag - HTML tag for the title
     * @param {string|null} messageClass - CSS class for the message
     * @param {boolean} isError - Indicates if it's an error message
     * @returns {string} - The formatted HTML message
     */
    _formatMessage(message, title, titleTag, messageClass = null, isError = false) {
        const titleHtml = title ? `<${titleTag} class="${isError ? '' : 'bold'}">${_t(title)}</${titleTag}>` : '';
        const messageHtml = messageClass ? `<p class="p ${messageClass}">${_t(message)}</p>` : `<p class="p ">${_t(message)}</p>`;
        return title ? `${titleHtml}${messageHtml}` : messageHtml;
    },

    /**
     * Displays a notification using alertify
     * @param {string} type - The type of notification ('success' | 'error')
     * @param {string} message - The formatted message to display
     */
    _showNotification(type, message) {
        alertify.set('notifier', 'position', 'top-right');
        alertify[type](message);
    },
};


// export const notify = {
//     success: function(message, title = null) {
//         message = title ? '<span>' + _t(title) + '</span><br>' + _t(message) : _t(message);
//         alertify.set('notifier', 'position', 'top-right');
         //TODO 29766 - Igor please remove second line and fix styles (it's just for test),
         // You can user success or .notify with custom second parameter like
        // alertify.notify(message, 'custom-success', 500, () => {}); it's add new class to notify
//         alertify.success(message);
//         alertify.notify(message, 'success', 500, () => {});
//     },
//     error: function(message, title = null) {
//         message = title ? '<span>' + _t(title) + '</span><br>' + _t(message) : _t(message);
//         alertify.set('notifier', 'position', 'top-right');
         //TODO 29766 - Igor please remove second line and fix styles (it's just for test),
         // You can user success or .notify with custom second parameter like
         // alertify.notify(message, 'custom-success', 500, () => {}); it's add new class to notify
//         alertify.error(message);
//         alertify.notify(message, 'error', 500, () => {});
//     },
// };

export function convertTime(time, userTimeFormat) {
    if (isMilitaryTimeFormat(userTimeFormat)) {
        return convertTimeFormat(time, MILITARY_TIME_FORMAT, MILITARY_TIME_FORMAT);
    }

    return convertTimeFormat(time, MILITARY_TIME_FORMAT, TIME_FORMAT);
    
}

export function isMilitaryTimeFormat(userTimeFormat) {
    return '24h' === userTimeFormat;
}

export function convertTimeFormat(value, from, to) {
    return moment(value, from).format(to);
}

export function getTimeFormat(userTimeFormat) {
    return isMilitaryTimeFormat(userTimeFormat) ? MILITARY_TIME_FORMAT : TIME_FORMAT;
}

export function fixLangFlag(lang) {
    switch (lang) {
        case 'sv':
            return 'se';
        case 'sl':
            return 'si';
        case 'gl':
            return 'es';
        default:
            return lang;
    }
}